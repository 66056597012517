<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { createAxiosInstance, createUnauthenticatedAxiosInstance } from '~/api/axiosClient';

createAxiosInstance();
createUnauthenticatedAxiosInstance();
</script>
