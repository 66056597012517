import { default as _91id_9310D9U5zzEXMeta } from "/vercel/path0/pages/[id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93BNYfJsgb2TMeta } from "/vercel/path0/pages/rate-us/[id].vue?macro=true";
export default [
  {
    name: "id",
    path: "/:id()",
    component: () => import("/vercel/path0/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "rate-us-id",
    path: "/rate-us/:id()",
    meta: _91id_93BNYfJsgb2TMeta || {},
    component: () => import("/vercel/path0/pages/rate-us/[id].vue").then(m => m.default || m)
  }
]