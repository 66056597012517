import axios, { type AxiosInstance } from 'axios';
import { useToast } from 'vue-toastification';
import { CookieKeyEnum } from '~/types';
import type { RequestPayload } from '~/types/request.interface';

let instance: AxiosInstance;
let unauthenticatedInstance: AxiosInstance;

export function createAxiosInstance() {
  const url = baseUrl();
  const header = getHeader();
  instance = axios.create({
    baseURL: url,
    headers: {
      ...header,
    },
  });
  instance.interceptors.request.use(
    (config) => {
      const token = useCookie(CookieKeyEnum.TOKEN).value;
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    function (response) {
      return response;
    },

    async (err) => {
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401) {
          useCookie('access_token').value = null;
          useToast().clear();
          useToast().info('Your login has expired. Please log in again.');
          navigateTo('/');
        }
        else {
          const errMessage = err.response.data.message;
          if (errMessage) useToast().error(errMessage);
          return Promise.reject(err);
        }
      }
    },
  );
}
export const client = {
  async get<T>(url: string) {
    const response = await instance.get<T>(url);

    return response.data;
  },

  async post<T>(url: string, data: RequestPayload) {
    const response = await instance.post<T>(url, data);

    return response.data;
  },

  async patch<T>(url: string, data: RequestPayload) {
    const response = await instance.patch<T>(url, data);

    return response.data;
  },

  async put<T>(url: string, data: RequestPayload) {
    const response = await instance.put<T>(url, data);

    return response.data;
  },
};

/**
 * Axios instance for unauthenticated requests
 * If an access token is available, it is added to the request headers for analytics
 */
export function createUnauthenticatedAxiosInstance() {
  const url = baseUrl();
  const header = getHeader();
  unauthenticatedInstance = axios.create({
    baseURL: url,
    headers: {
      ...header,
    },
  });

  unauthenticatedInstance.interceptors.response.use(
    function (response) {
      return response;
    },

    async (err) => {
      const errMessage = err.response.data.message;
      if (errMessage) useToast().error(errMessage);
      return Promise.reject(err);
    },
  );
}

/**
 * Axios client for unauthenticated requests
 */
export const unauthenticatedClient = {
  async get<T>(url: string) {
    const response = await unauthenticatedInstance.get<{ data: T }>(url);
    return response.data?.data;
  },

  async post<T>(url: string, data: RequestPayload) {
    const response = await unauthenticatedInstance.post<{ data: T }>(url, data);
    return response.data?.data;
  },
};

export function baseUrl() {
  const runtimeConfig = useRuntimeConfig();
  const publicEnv = runtimeConfig.public;
  return `${publicEnv.API_URL}/customer/v1`;
}
export function getHeader() {
  return { Authorization: `Bearer ${useCookie('access_token').value}` };
}
