import * as Sentry from '@sentry/vue';
import { getEnvironment } from '~/composables/environment';

export default defineNuxtPlugin((nuxtApp) => {
  const environment = getEnvironment();
  const { vueApp } = nuxtApp;
  if (environment !== 'loc') {
    Sentry.init({
      app: [vueApp],
      dsn: SentryConfig.dsn,
      environment,
      integrations: [
        Sentry.browserTracingIntegration({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          router: nuxtApp.$router as any,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          console.error(
            `[Exeption handled by Sentry]: (${hint.originalException})`,
            { event, hint },
          );
        }
        // Continue sending to Sentry
        return event;
      },
    });
  }

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }),
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
