import { jwtDecode } from 'jwt-decode';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { CookieKeyEnum, type JwtPayloadInterface } from '~/types';

export default defineNuxtRouteMiddleware((to, _from) => {
  const token = useCookie(CookieKeyEnum.TOKEN).value;
  if (to.path.startsWith('/rate-us/')) {
    return true;
  }
  if (!token && to.path !== '/') {
    return { path: '/' };
  }
  if (to.path !== '/') {
    try {
      if (!isValidToken(token, to)) {
        useCookie(CookieKeyEnum.TOKEN).value = null;
        return { path: '/' };
      }
      return true;
    }
    catch {
      useCookie(CookieKeyEnum.TOKEN).value = null;
      return { path: '/' };
    }
  }
  if (to.path === '/' && isValidToken(token, to)) {
    const tokenPayload = jwtDecode<JwtPayloadInterface>(token!);
    return { path: `/${tokenPayload.orderType}-${tokenPayload.orderId}` };
  }
  return true;
});

function isValidToken(
  token: string | null | undefined,
  to: RouteLocationNormalizedLoaded,
) {
  if (!token) return false;
  const tokenPayload = jwtDecode(token) as JwtPayloadInterface;
  if (tokenPayload.exp <= Date.now() / 1000) {
    return false;
  }
  const tokenOrderId = `${tokenPayload.orderType}-${tokenPayload.orderId}`;
  const toOrderId = to.params.id;
  if (!toOrderId || !tokenOrderId) {
    return false;
  }
  if (tokenOrderId !== toOrderId) {
    return false;
  }
  return true;
}
